<template>
  <section class="photosViwer">
    <iconic class="photosViwer__iconClose" name="close" @click.native="closeModal" />
    <VueSlickCarouselc  :lazyLoad="'progressive'" VueSlickCarouselcv-bind="settings" class="photosViwer__carrousel" style="width: 100%; height: 100%">
      <template #prevArrow="">
        <div class="photosViwer__customArrow">
          <iconic class="photosViwer__iconArrow photosViwer__iconArrow--prev" name="arrow_right" />
        </div>
      </template>
      <div class="photosViwer__contentImg" v-for="(photo, idx) in data.images" :key="idx">
        <img class="photosViwer__photoImg" :src="photo.link" :alt="photo.name" />
      </div>
      <template #nextArrow="">
        <div class="photosViwer__customArrow">
          <iconic class="photosViwer__iconArrow photosViwer__iconArrow--next" name="arrow_right" />
        </div>
      </template>
    </VueSlickCarouselc>
  </section>
</template>

<script>
export default {
  components: {
    VueSlickCarouselc: () => import("vue-slick-carousel"),
  },
  computed: {
    data() {
      return this.$store.state.records.imagesToShow;
    },
    settings() {
      return {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchThreshold: 5,
        initialSlide: this.data.initialSlide,
      };
    },
  },
  methods: {
    closeModal() {
      this.$store.state.records.imagesToShow = {
        images: [],
        initialSlide: 0,
        serviceId: "",
      };
    },
  },
};
</script>

<style lang="scss">
.photosViwer {
  @include Flex(row);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  .slick-list,
  .slick-track {
    height: 100%;
    max-height: 100%;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }
  .slick-disabled {
    display: none;
  }
  .slick-slide {
    > div {
      width: 100%;
      height: 100%;
    }
  }
  &__iconClose {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  &__carrousel {
    max-height: 700px;
    max-width: 700px;
    height: 100%;
    width: 100%;
  }
  &__customArrow {
    @include Flex(row);
    top: 15px;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }
  &__iconArrow {
    color: white;
    font-size: 28px;
    &--prev {
      transform: rotateY(180deg);
    }
  }
  &__contentImg {
    position: relative;
    height: 100%;
    width: 100%;
  }
  &__photoImg {
    padding: 0 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    max-height: 700px;
    max-width: 700px;
    object-fit: contain;
    background-position: center center;
  }
  @media screen and (min-width: $tablet_width) {
    &__iconClose {
      top: 40px;
      right: 40px;
      font-size: 25px;
    }
    .slick-prev {
      left: -30px;
    }
    .slick-next {
      right: -30px;
    }
    &__iconArrow {
      font-size: 38px;
    }
  }
}
</style>
